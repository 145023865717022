.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


html, 
body {
    height: 100%;
    margin: 100 !important;
}


.trapecio-top {
  background-color: blue;
}

.triangulo-equilatero-bottom-left {
  width: 0;
  height: 0;
  border-right: 42px solid transparent;
  border-top: 42px solid transparent;
  border-left: 42px solid #252424;
  border-bottom: 42px solid #393838;
  justifySelf: center;
  alignSelf: flex-start;
}

.triangulo-equilatero-bottom-right {
  width: 0;
  height: 0;
  border-right: 42px solid #252424;
  border-top: 42px solid transparent;
  border-left: 42px solid transparent;
  border-bottom: 42px solid #393838;
}


.triangulo-equilatero-top-left {
  width: 0;
  height: 0;
  border-right: 42px solid transparent;
  border-top: 42px solid #393838;
  border-left: 42px solid #252424;
  border-bottom: 42px solid transparent;
}

.triangulo-equilatero-top-right {
  width: 0;
  height: 0;
  border-right: 42px solid #252424;
  border-bottom: 42px solid transparent;
  border-left: 42px solid transparent;
  border-top: 42px solid #393838;
}


.cardBackground {
  background-image: radial-gradient(circle, rgba(2,0,36,0.908000700280112) 59%, rgba(41,27,135,0.908000700280112) 98%, rgba(0,0,0,0.7539390756302521) 99%), url("/src/assets/coding.jpg");
  background-size:cover;
  padding: 55px;
}


