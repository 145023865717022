body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    margin: 0 !important;
}

* {
  margin: 0;
  padding: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.hrLine{
  
  borderLeft: 1px solid hsla(200, 10%, 50%,100);
  height: 200px;
  width: 1px;
}

.cardsCotatainer{
  color: whitesmoke;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  gap: 55px;
}

p{
  width: 300px
}


@media screen and (max-width: 1100px) {
  .hrLine{
    border:  1px solid hsla(200, 10%, 50%,100);
    height: 1px;
    width: 100%;
  }

  .cardsCotatainer{
    color: whitesmoke;
    display: grid;
    grid-auto-flow: row;
    justify-content: center;
    align-items: center;
    gap: 55px;
  }
}
